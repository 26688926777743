@media (min-width: 768px) {


  .logo-nav-h3{
    padding-top: 2%;
  }

#navbar-3{

                    color: rgba(0,0,0,.87);
                    border-bottom-color: rgba(0,0,0,.42);
                    font-size: 1rem;
                    font-weight: 400;
                    letter-spacing: .009375em;
                    text-decoration: inherit;
                    text-transform: inherit;
                    align-self: flex-end;
                    box-sizing: border-box;
                    width: 40%;
                    padding: 0 1%;
                    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
                    border: none;
                    border-bottom: 1px solid;
                    border-radius: 4px 4px 0 0;
                    background: rgb(245, 245, 245);
                    height: 38px;
                                 
}

  #banner-card-n-3{
    padding: 19% 3%;
  }
    #main-section-n-3{
    margin-top: 4vh;
  }

#newly-news{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  position: relative;
  }

.grid-container-mt3{
  padding-top: 1%;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-evenly;
}

#grid-container-3{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}

#most-trending-home3{
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;

}
.grid-container-s-3{
  display: grid;
  grid-template-columns: 24% 75%;
  justify-content: space-between;
}

#carousel-img-3{
  height:45vh; 
  width:100%;
  border-radius: 10px;
}
#slider-img-s-3{
  height: 45vh;
  border-radius: 10px;
  width: 100%;
}
#carousel-div-3{
  margin-top: 1%;
  border-radius: 10px;
  height: 48.5vh;
  box-shadow: 1px 1px 1px white,
    2px 2px 1px white,
    3px 3px 1px gray,
    4px 4px 1px white,
    5px 5px 1px white,
    6px 6px 1px gray,
    7px 7px 1px white,
    8px 8px 1px white,
    15px 15px 10px rgba(0,0,0,.3);
}

.searchTerm-3 {
  width: 40%;
  height: 20px;

  border-right: none;
  padding: 5.5px 5px 5px 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  margin: 10px 0 0 5%;
}
#navbar-en-3{ 
               
                
                display: inline-block;
                outline: 0;
                border: 0;
                cursor: pointer;
                font-weight: 600;
                color: #fff;
                font-size: 14px;
                height: 38px;
                padding: 8px 10px;
                border-radius: 50px;
                background-image: linear-gradient(180deg,#7c8aff,#3c4fe0);
                box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
                transition: all .2s ease-out;
                margin-left: 11%;
               
                
                
}

#navbar-en-3::hover {
                    box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
                    transform: translateY(-2px);
                }
 #navbar-en-3:active{
                    box-shadow: inset 0px 3px 7px #3c4fe0;
                    transform: translateY(2px);
                }

                .wrappern {
  margin: 0;
  color: #000;
  font-family: 'Muli', sans-serif;
  font-size: 1rem;
  display: -ms-grid;
  display: grid;
  height: 530px;
}


}


@media (max-width: 768px) {

  .card-h3{
    margin: 20px 0;
    padding: 5px 20px;
    width: 160px !important;
    height: 200px;
    display: grid;
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .logo-nav-h3{
    margin: 0 0 0 5px !important;
  }

  #mt-h3{
    margin-top: 4vh;
  }


#grid-container-3{
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}

#banner-card-n-3, #banner-card-n-3 img{
  height: 40vh;
}

#navbar-3{

                    color: rgba(0,0,0,.87);
                    border-bottom-color: rgba(0,0,0,.42);
                    font-size: 1rem;
                    font-weight: 400;
                    letter-spacing: .009375em;
                    text-decoration: inherit;
                    text-transform: inherit;
                    align-self: flex-end;
                    box-sizing: border-box;
                    width: 80%;
                    padding: 0 1%;
                    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
                    border: none;
                    border-bottom: 1px solid;
                    border-radius: 4px 4px 0 0;
                    background: rgb(245, 245, 245);
                    height: 38px;
                                 
}

#mt-3-m{
  margin-top: 2vh;
}

#carousel-div-3{
  box-shadow: 1px 1px 1px white,
    2px 2px 1px white,
    3px 3px 1px gray,
    4px 4px 1px white,
    5px 5px 1px white,
    6px 6px 1px gray,
    7px 7px 1px white,
    8px 8px 1px white,
    5px 5px 2px rgba(0,0,0,.3);
}


  #newsletter3{
    display: none;
  }

  #carousel-img-3{
    margin: 3vh 0;
  height:25vh; 
  width:100%;
  border-radius: 10px;
}


      #main-section-n-3{
    margin-top: 8vh;
  }


  #nav-strip{
    display: none;
  }

  .nav-margin{
    display: none;
  }
  #mts-h3-head{
    margin-top: 4vh;
  }

.grid-container-mt3{
  padding-top: 1%;
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
}

.searchTerm-3 {
  width: 80%;
  height: 20px;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  margin-top: 2%;
}
#navbar-en-3{
  display: none;
}

}



.twelve h1 {
  font-size:19px; 
  font-weight:700;  
  letter-spacing:1px; 
  text-transform:uppercase; 
  width:350px; 
  text-align:center; 
  margin:auto; 
  white-space:nowrap; 
  padding-bottom:13px;
  color: #b80979;
}

.twelve h1:after {
    background-color: #b80979;
    color: #b80979;
    content: '';
    display: block;
  position:absolute; left:14%; bottom:0;
    height: 3px;
    width: 100px;
    margin-bottom: 0.25em;
}


.subscribe-wrapper {
  position: absolute;
  height: 180px;
  padding: 30px;
  width: 45%;
  background-image: url(../images/newsletter4.png);
  background-position-x: -12%;
  background-position-y: -1px;
  background-repeat: no-repeat;
  background-color: #FFF;
  border-radius: 4px;
  color: #333;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
}

.subscribe-wrapper:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 71px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #b80979;
}

.subscribe-wrapper h4 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 28px;
}

.subscribe-wrapper input {
  position: absolute;
  bottom: 30px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  width: 65%;
  background: transparent;
  transition: all .25s ease;
  color: rgb(251, 90, 188);
}

.subscribe-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #a77cf4;
}

.subscribe-wrapper .submit-btn {
  position: absolute;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: linear-gradient(90deg, #e90a96 20%, #88085c 200%);
  color: #FFF;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  right: -10px;
  bottom: 30px;
  cursor: pointer;
  transition: all .25s ease;
  box-shadow: -5px 6px 20px 0px rgba(51, 51, 51, 0.4);
}

.subscribe-wrapper .submit-btn:hover {
  background-color: rgb(251, 20, 188);
}

 #navbar-3:hover{
                        border-bottom-color: rgba(0,0,0,.87);
                        background: #ececec;
                    }
                   #navbar-3:focus{
                        border-color: #6200ee;
                    }

                    #show-code-3{

             
                
                    display: inline-block;
                    outline: 0;
                    border: 0;
                    cursor: pointer;
                    background-color: #4299e1;
                    border-radius: 4px;
                    padding: 4px 9px;
                    font-size: 15px;
                    border-bottom: 4px solid #ffeaf6;
                    font-weight: 700;
                    color: #ffeaf6;
                    line-height: 26px;
                position: relative;
                left: 65%;
                bottom: 7vh;
                margin: 2% 5% 1% 0;
                    }
#show-code-3:hover {
                    box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
                    transform: translateY(-2px);
                }
                #show-code-3:active{
                    box-shadow: inset 0px 3px 7px #d6d6e7;
                    transform: translateY(2px);
                }

                #button-3 {
  display: inline-block;
  background-color: rgb(189, 16, 224);
  width: 50px;
  height: 60px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 1;
  visibility: visible;
  padding-bottom: 5px;
}

#button-3::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
#button-3:hover {
  cursor: pointer;
  background-color: #333;
}
#button-3:active {
  background-color: #555;
}

/* CARDS */

.card-h3{
  margin: 20px 0;
  padding: 5px 20px;
  width: 180px;
  height: 230px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.card-h3:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 300;
  font-size: 1.2rem;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-0 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-1 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-2 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.card-3 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.card-4 {
  background: radial-gradient(#f588d8, #c0a3e5);
}
.card-5 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-6 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-7 {
  background: radial-gradient(#76b2fe, #b69efe);
}


/* RESPONSIVE */

@media (max-width: 1600px) {


  .cards {
    justify-content: center;
  }
}

.rc-footer{
  background-color: #b80979 !important;
}

.rc-footer-column{
  margin: 0 !important;
  padding: 0 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px !important; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(251, 90, 188) !important;
}

.custom-scrollbar2::-webkit-scrollbar {
  width: 10px !important; 
}

.custom-scrollbar2::-webkit-scrollbar-thumb {
  background-color: rgb(238, 238, 16) !important;
}

