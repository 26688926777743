/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #searchBar{
    width: 130%;
    margin-left: 50%;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #searchBar{
    width: 120%;
    margin-left: 40%;
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #searchBar{
    width: 220%;
    margin-left: 40%;
  }
}
@media only screen and (min-width: 1400px) {
  #searchBar{
    width: 220%;
    margin-left: 75%;
  }
}

#search-input{
  margin-left: 2vw;
    border-radius: 4px 0 0 4px; 
    height: 2rem;
    width:80%;
    outline:none;
}
#search-input:focus{
   border: none;
    outline:none;
    box-shadow: none;
}

