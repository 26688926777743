

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}

.thirteen{
  padding: 2vh 0 1vh 0 ;
}

.thirteen h1 {
  position:relative; font-size:.7rem; font-weight:700;  letter-spacing:0px; text-transform:uppercase; width:11rem; text-align:center; margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
}
.thirteen h1:before, .thirteen h1:after {
    background-color: black;
    position:absolute; 
    content: '';
    height: 7px;

    width: 7px; border-radius:50%;
    bottom: 12px;
}
.thirteen h1:before {
   left:-20px;
}
.thirteen h1:after {
   right:-20px;
}

/*cards*/

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
  margin-bottom: 3%;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
}

.tag2 {
  background: #cccccc;
  border-radius: 50px;
  font-size: 8px;
  margin: 4px 1px;
  color: #fff;
  padding: 1px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag2-teal {
  background-color: #47bcd4;
}
.tag2-purple {
  background-color: limegreen;
  font-weight: bold;
}
.tag2-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
}


/*button*/


#show-code {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  font-size: 12px;
  height: 25px;
  padding: 2px 6px;
  border-radius: 50px;
  box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
  transition: all .2s ease-out;
  margin: 4px 8px;

  /* Vendor prefixes */
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
  -moz-box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
}

#show-code:hover {
  box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%), 0 4px 6px 0 rgb(93 100 148 / 20%);

  /* Vendor prefixes */
  -webkit-box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%), 0 4px 6px 0 rgb(93 100 148 / 20%);
  -moz-box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%), 0 4px 6px 0 rgb(93 100 148 / 20%);
}

/*newsletter*/

.containern {
  background-color: #fff;
  padding: 1em;
  border-radius: 1em;
  max-width: 768px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  -webkit-box-shadow: 0px 10px 24px -10px #eeee10;
          box-shadow: 0px 10px 24px -10px #eeee10;
}

.title {
  font-size: 1.6rem;
  font-weight: 700;
}

.text {
  font-size: 1rem;
  margin-bottom: 0.8em;
}

.containern {
  max-width: 380px;
  overflow: hidden;
  padding: 0;
}

.img {
  width: 100%;
  height: auto;
  border-radius: 1em 1em 0 0;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.img__container {
  height: 100px;
  overflow: hidden;
  margin-bottom: 1.5em;
}

.title {
  margin-bottom: 0.9em;
}

.subtitle {
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.content {
  display: -ms-grid;
  display: grid;
  place-items: center;
  padding: 0 2em;
  margin-bottom: 1.8em;
}

.mail {
  padding: 0.5em 0 0.5em 1.5em;
  line-height: 3;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 0.5em;
  margin-bottom: 1em;
}

::-webkit-input-placeholder {
  color: #111127;
}

:-ms-input-placeholder {
  color: #111127;
}

::-ms-input-placeholder {
  color: #111127;
}

::placeholder {
  color: #111127;
}

.subscribe {
  color: black;
  font-size: 1.3rem;
  font-weight: 700;
  background-color: #eeee10;
  padding: 0.9em 0;
  display: inline-block;
  border: none;
  border-radius: 0.5em;
  width: 100%;
  margin-bottom: 1.3em;
}

.text {
  font-size: 0.9rem;
  width: 90%;
  text-align: center;
}

/* FOOTER */
.footer {
  width: 100%;
  background: #00121b;
  display: block;
 }

 .inner-footer {
   width: 95%;
   margin: auto;
   padding: 30px 10px;
   display: flex;
   flex-wrap: wrap;
   box-sizing: border-box;
   justify-content: center;
 }

.footer-items {
  width: 33%;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
}

.footer-items p {
  font-size: 16px;
  text-align: justify;
  line-height: 25px;
  color: #fff;
}

.footer-items h1 {
  color: #fff;
}

.border1 {
  height: 3px;
  width: 40px;
  background: #ff9800;
  color: #ff9800;
  background-color: #ff9800;
  border: 0px;
}

.footer-ul {
  list-style: none;
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.5px;  
 }

.footer-ul a {
  text-decoration: none;
  outline: none;
  color: #fff;
  transition: 0.3s;
}

.footer-ul a:hover {
  color: #ff9800;
}

.footer-ul li {
  margin: 10px 0;
  height: 25px;
}

.footer-ul li i {
  margin-right: 20px;
}

.social-media {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.social-media a {
  text-decoration: none;
}

.social-media i {
  height: 25px;
  width: 25px;
  margin: 20px 10px;
  padding: 4px;
  color: #fff;
  transition: 0.5s;
}

.social-media i:hover {
  transform: scale(1.5);
}

.footer-bottom {
  padding: 10px;
  background: #00121b;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

/* for tablet mode view */

@media screen and (max-width: 1275px) {
  .footer-items {
    width: 50%;
  }
}

/* for mobile screen view */

@media screen and (max-width: 660px) {
  .footer-items {
    width: 100%;
  }

}

/*slider*/


@media (min-width: 768px) {

  .home-2-catgories{
   margin-top: 33px;
  }

  #banner-card-n{
    padding: 10% 6%;
  }

  #main-section-n{
    margin-top: 5vh;
  }

   #menu {
  padding:4px 2px 2px 2px;
}

#logo-nav{

                              height: 50px;
                              width: 17vw;


}
#nav-ul-n{
  display: none !important;
}

.searchTerm {
  width: 40%;
  height: 20px;
  border: 3px solid #00B4CC;
  border-right: none;
  padding: 5.5px 5px 5px 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  margin: 10px 0 0 5%;
}
#navbar-en{

                    
                    display: inline-block;
                    outline: none;
                    cursor: pointer;
                    font-weight: 600;
                    border-radius: 3px;
                    padding: 12px 24px;
                    border: 0;
                    color: #fff;
                    background: #ff5000;
                    line-height: 1.15;
                    font-size: 16px;
                    
                
                    margin-left: 12%;
}

#navbar-en:hover {
                        transition: all .1s ease;
                        box-shadow: 0 0 0 0 #fff, 0 0 0 3px #1de9b6;
                    }

#page-mt{
  padding: 2% 0 4% 0;
}
.carousel-img{
  height:52.4vh; 
  width:100%;
  border-radius: 10px;
}
.grid-container {
  padding-top: 1%;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-evenly;
}
#grid-container{
  width: 93vw;
  padding-left: 5vw;
}
.grid-container2{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  
  justify-content: space-between;
}

.grid-container3{
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  justify-content: space-between;
}
.wrappern {
  margin: 0;
  color: #000;
  font-family: 'Muli', sans-serif;
  font-size: 1rem;
  display: -ms-grid;
  display: grid;
  height: 530px;
}
#newly-head{
  padding-left: 11%;
}
.grid-container-s{
  display: grid;
  grid-template-columns: 66% 23%;
  justify-content: space-evenly;
  margin: 1% 0 0 0;
}
#slider-img-s{
  height: 50vh;
  border-radius: 10px;
  width: 100%;
}
#carousel-div{
  border-radius: 10px;
  height: 50vh;

box-shadow:
        -5px 5px 0px rgba(33, 33, 33, 1), 
        -12px 12px 0px rgba(33, 33, 33, 0.7),
         -19px 19px 0px rgba(33, 33, 33, 0.4), 
         -25px 25px 0px rgba(33, 33, 33, 0.1);
}
#most-trending-n{
   box-shadow: -2px 1px 0px #FDE68A75;
}
.card-header img {
  width: 100%;
  height: 254px;
  object-fit: cover;
}


}
@media (min-width: 768px) {
  #h2-nav-res-li{
    margin-right: 14vw;
    }
}

@media (min-width: 1100px) {
  #h2-nav-res-li{
    margin-right: 18vw;
    }
}
@media (min-width: 1300px) {
  #h2-nav-res-li{
    margin-right: 28vw;
    }

}

@media (max-width: 768px) {

  #home3-deas-card-img{
   max-width:180px !important;
   max-height: 150px;
   object-fit: contain;
  }
  .card-header{
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  #deals-cards-home3{
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
  }

  .h3-cards-deals{
    height: 270px;
  }

  .h3-deals-banner{
    object-fit: contain !important;
  }

  .mobile-hidden-h3{
    display: none !important;
  }
  .banners-deals-h3{
    height: 270px !important;
  }
  #show-code-3{
    position: static !important;
  }

  #home1-banner{
    /* width: 100vw; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-de-ho{
    margin-left: 5vw !important;
  }
  .slick-slide > div {
    height: 350px !important;
  }
  .default-home-b{
    margin: 0 !important;
    padding: 0 !important;
  }

.box-icon{
  margin-left: 15vw;
}

#h2-foot-logo{
  margin: 0 !important;
  padding: 0 !important;
}
.footer-items{
  margin: 0 8% !important;
  padding: 0 !important;
}

  #home1-banner{
    padding: 20% 11% !important;
    margin-bottom: 3% !important;
  }
  #navbar-en{
    display: none;
  }
  #mobile-hidden-nav{
    display: none;
  }

  #main-section-n{
    margin-top: 2vh;
  }

  #menu {
  padding: 15px;
}

  #banner-card-n{
    padding: 40% 2%;
  }

.searchTerm {
  width: 80%;
  height: 20px;
  border: 3px solid #00B4CC;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  margin-top: 2%;
}

.card-header img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.carousel-img{
  height:25vh; 
  width:100vw;
}
#page-mt{
  padding: 0 0 7% 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-evenly;
}
.grid-container2{
  display: grid;
  grid-template-columns: 49% 49%;
  
  justify-content: space-between;
}
.wrappern {
  margin: 4vh 0;
  color: #000;
  font-family: 'Muli', sans-serif;
  font-size: 1rem;
  display: -ms-grid;
  display: grid;
}
#most-trending-n{
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
#slider-img-s{
  height: 35vh;
  width: 100%;
  border-radius: 10px;
}
#carousel-div{
  height: 35vh;
  margin: 3% 8% 5% 11%;
  box-shadow:
        -5px 5px 0px rgba(33, 33, 33, 1), 
        -8px 8px 0px rgba(33, 33, 33, 0.7),
         -12px 12px 0px rgba(33, 33, 33, 0.4), 
         -12px 12px 0px rgba(33, 33, 33, 0.1);
}
#logo-nav{

  height: 50px;
  width: 80px;
}

}

/* Navigation */
#menu {
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-shadow: 3px solid purple;
}
#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 2px 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/*searchbar*/
.search {
  width: 100%;
  position: relative;
  display: flex;
}


.searchTerm:focus{
  color: #00B4CC;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00B4CC;
  background: #00B4CC;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
