.grid-container-h4-vb-mt{
  display: grid;
  grid-template-columns: 18% 78%;
  justify-content: space-evenly;
}
#h4-sqare-baner{
  height: 84%
}
#h4-news-sqb{
  display: grid;
  grid-template-columns:  17.3% 66.2% 17.3%;
  justify-content: space-between;
}
.newsletter-icon-h4{
  font-size: 42px;
    width: 62px;
    height: 60px;
    padding: 15px 0;
    background: linear-gradient(to right, #DC2626 0%, #DC2626 100%);
    border-radius: 50%;
    color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.h4-section-header{
  font-size:.9rem;
  color:#FEE2E2 ;
  borderLeft:6px solid #B91C1C;
  borderBottom:2px dotted white;
  borderTop:2px dotted white;
  background:#EF4444;
   padding:4px 6px;
}

#h4-mt-cont{
  padding: 2% 15px 2% 0;
}
#store-tab-banner-h4{
  margin-bottom: 2.5%;
}

.carousel-stores-h4-grid{
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  justify-content: space-evenly;
}
.carousel-stores-h4-grid-new{
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  justify-content: space-evenly;
}
.newly-banner-h4{
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-evenly;
}

#h4-nav-input{
  width: 25%;
  color: rgb(36, 35, 42);
  font-size: 16px;
  line-height: 20px;
  min-height: 28px;
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  background: rgb(251, 251, 251);
  transition: all 0.1s ease 0s;
  border-radius: -25px;
  
}

#h4-nav-search-but{
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: #4299e1;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  line-height: 26px;
} 

  #carousel-img-4{
  width:100%;
  border-radius: 10px;
  object-fit: contain;
}             
#h4-nav-search-but:hover{
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}
.h4-news-max-height{
  height: 200px !important;
}

@media (min-width: 768px) {
  #logo-nav-h4{
    width: 10vw;
    margin-right: 100px;
  }
}


@media (max-width: 768px) {

.mobile-hidden-banner-h4{
  display: none;
}

#h4-news-sqb{
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
  .h4-newsletter{
    margin-top: 9px;
  }

#h4-sqare-baner{
  height: 100%
}
  #h4-subscribe-input{
    width: 76%;
  }
  .h4-footer{
    margin: 15px 0 !important;
  }
#store-tab-banner-h4{
  display: none;
}

  #h4-nav-input{
  margin-top: 3px;
  width: 75%;
}
  .grid-container-h4-vb-mt{
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-evenly;
}
.newly-banner-h4{
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
}

.carousel-stores-h4-grid-new{
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-evenly;
}

  .carousel-stores-h4-grid{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-evenly;
}


#h4-mt-cont{
  padding: 2% 0 2% 0;
}
.h4-section-header{

   margin: 15px auto;
}

.hide-below768{
  display: none;
}
}