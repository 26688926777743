.grid-container-h4-vb-mt{
    display: grid;
    grid-template-columns: 18% 78%;
    justify-content: space-evenly;
  }
  #h4-sqare-baner{
    height: 84%
  }
  #h4-news-sqb{
    display: grid;
    grid-template-columns:  17.3% 66.2% 17.3%;
    justify-content: space-between;
  }


  .newsletter-icon-h4{
    font-size: 42px;
      width: 62px;
      height: 60px;
      padding: 15px 0;
      background: linear-gradient(to right, #DC2626 0%, #DC2626 100%);
      border-radius: 50%;
      color: #fff;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
  
  .h4-section-header{
    font-size:.9rem;
    color:#FEE2E2 ;
    borderLeft:6px solid #B91C1C;
    borderBottom:2px dotted white;
    borderTop:2px dotted white;
    background:#EF4444;
     padding:4px 6px;
  }
  
  #h4-mt-cont{
    padding: 2% 15px 2% 0;
  }
  #store-tab-banner-h4{
    margin-bottom: 2.5%;
  }
  
  .carousel-stores-h4-grid{
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    justify-content: space-evenly;
  }
  .carousel-stores-h4-grid-new{
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
    justify-content: space-evenly;
  }
  .newly-banner-h4{
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-evenly;
  }
  
  #h4-nav-input{
    width: 25%;
    color: rgb(36, 35, 42);
    font-size: 16px;
    line-height: 20px;
    min-height: 28px;
    border-radius: 4px;
    padding: 8px 16px;
    border: 2px solid transparent;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    background: rgb(251, 251, 251);
    transition: all 0.1s ease 0s;
    border-radius: -25px;
    
  }
  
  #h4-nav-search-but{
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    background-color: #4299e1;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    line-height: 26px;
  } 
         
  #h4-nav-search-but:hover{
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  }
  .h4-news-max-height{
    height: 200px !important;
  }
  
  @media (min-width: 768px) {

   
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #a371f7;
  padding: 5px 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
} 

.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

    .post-module .post-content .sub_title {
      margin: 0;
      padding: 0;
      color: #7C3AED;
      font-size: 20px;
      font-weight: 400;
    }

    #h5-newly-tabs{
      margin-left: 8px;
    }

    
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #FFFFFF;
  min-width: 270px;
  height: 470px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.Subscribe-h5-sub form input[type=text] {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 15px;
  padding: 0 110px 0 20px;
  font-family: "Open Sans", sans-serif;
}

    .container-deals-h5 .info {
      width: 300px;
      margin: 25px auto 10px auto;
      text-align: center;
    }

    
.Subscribe-h5-sub form {
  z-index: 2;
  left: 10px;
  width: 230px;
  height: 46px;
  bottom: 25px;
  overflow: hidden;
  position: absolute;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(149, 144, 179, 0.2);
  transition: transform 600ms cubic-bezier(0.2, 0, 0, 1);
  font-size: .3rem;
}

.Container-h5-sub {
  width: 250px;
}

.Subscribe-h5-sub {
  height: 265px;
  padding: 15px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba(149, 144, 179, 0.15), 0 15px 50px 0 rgba(182, 182, 224, 0.1);
}
    #carousel-img-5{
      width:100%;
      border-radius: 10px;
      object-fit: cover;
      height: 350px;
    }    
    
#h5-nav-input{
  width: 30%;
  height: 36px;
  border-radius: 4px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid rgb(137, 151, 155);
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  margin-right: 1px;
}

    #logo-nav-h4{
      width: 10vw;
      margin-right: 100px;
    }

    #h5-banner-grid{
      display: grid;
      grid-template-columns: 48% 48%;
      justify-content: space-evenly;
      margin-top: 20px;
    }
    
    #h5-footer-grid{
      display: grid; 
      grid-template-columns: 20% 20% 20% 20% 20%;
       padding: 15px 0 60px 0;
    }

    .grid-container2-deals-h5{
      display: grid;
      grid-template-columns: 32.2% 32.2% 32.2%;
      justify-content: space-between;
    }

    .h5-footer-li{
      display: flex; 
      justify-content: center; 
      align-items: center; 
      gap: 5px;
    }

    
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}

.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}

.post-module .thumbnail img {
  display: block;
  width: 110%;
  object-fit: cover;
  height: 220px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}


.post-module .thumbnail {
  background: #fff;
  height: 220px;
  overflow: hidden;
}

  }
  
  
@media (max-width: 768px) {
 
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #a371f7;
  padding: 5px 15px;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
 
.post-module .post-content {
  height: 260px;
}
 
.post-module .thumbnail {
  background: #fff;
  height: 10px;
}

    .post-module .thumbnail img {
      display: block;
      width: 100%;
      object-fit: cover;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
    }
    
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
  font-size: 9px;
}

.post-module .post-content .post-meta {
  margin: 10px 0 0;
  color: #999999;
}
    
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
}

    .post-module .post-content .sub_title {
      margin: 0;
      padding: 0;
      color: #7C3AED;
      font-size: 14px;
      font-weight: 400;
    }
 
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #FFFFFF;
  min-width: 160px;
  height: 400px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.grid-container2-deals-h5{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.Subscribe-h5-sub form input[type=text] {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 15px;
  padding: 0 110px 0 20px;
  font-family: "Open Sans", sans-serif;
}

    #h5-footer-foot{
      margin-bottom: 10px;
    }

    .h5-footer-li{
      display: flex; 
      justify-content: center; 
      align-items: center; 
      gap: 5px;
      margin-bottom: 15px;
    }

    #h5-footer-grid{
      grid-template-columns: 100%;
    }

    .h5-deals-col{
      margin-bottom: 10px;
      margin-left: 15px;
    }

    .container-deals-h5 .info {
      width: 300px;
      margin: 10px auto 10px auto;
      text-align: center;
    }

.Subscribe-h5-sub form {
  z-index: 2;
  left: 20px;
  width: 290px;
  height: 46px;
  bottom: 25px;
  overflow: hidden;
  position: absolute;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(149, 144, 179, 0.2);
  transition: transform 600ms cubic-bezier(0.2, 0, 0, 1);
  font-size: .3rem;
}

.Container-h5-sub {
  width: 330px;
  margin-left: 15px;
}

.Subscribe-h5-sub {
  height: 300px;
  padding: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba(149, 144, 179, 0.15), 0 15px 50px 0 rgba(182, 182, 224, 0.1);
}

    #carousel-img-5{
      width:100%;
      border-radius: 10px;
      object-fit: cover;
      height: 150px;
      margin-bottom: 7px;
    }    
    
#h5-nav-input{
  width: 78%;
  height: 36px;
  border-radius: 4px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid rgb(137, 151, 155);
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  margin-right: 1px;
}
  
  .mobile-hidden-banner-h4{
    display: none;
  }
  
  #h4-news-sqb{
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
    .h4-newsletter{
      margin-top: 9px;
    }
  
  #h4-sqare-baner{
    height: 100%
  }
    #h4-subscribe-input{
      width: 76%;
    }
    .h4-footer{
      margin: 15px 0 !important;
    }
  #store-tab-banner-h4{
    display: none;
  }
  
    #h4-nav-input{
    margin-top: 3px;
    width: 75%;
  }
    .grid-container-h4-vb-mt{
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-evenly;
  }
  .newly-banner-h4{
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
  }
  
  .carousel-stores-h4-grid-new{
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-evenly;
  }
  
    .carousel-stores-h4-grid{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-evenly;
  }
  
  
  #h4-mt-cont{
    padding: 2% 0 2% 0;
  }
  .h4-section-header{
  
     margin: 15px auto;
  }
  
  .hide-below768{
    display: none;
  }
  }


@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

#cate-h5 {
  text-align: center;
}

#cate-h5-ol {
  width: min(60rem, 100%);
  margin-inline: auto;

  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  list-style: none;
  counter-reset: cardnr;
  justify-content: center;
}

.cate-h5-li {
  --frontColor: white;
  --width: 10em;
  --inlineP: 0.5rem;
  --borderR: 4rem;
  --arrowW: 2rem;
  --arrowH: 1.5rem;

  counter-increment: cardnr;
  width: calc(var(--width) - var(--inlineP) * 2);
  display: grid;
  grid-template:
    "icon" var(--borderR)
    "title"
    "descr" 1fr;
  margin-inline: var(--inlineP);
  margin-bottom: calc(var(--borderR));
  position: relative;
}
.cate-h5-li .icon,
.cate-h5-li .title,
.cate-h5-li .descr {
  background: var(--frontColor);
  padding-inline: 1rem;
  padding-bottom: 1rem;
}
.cate-h5-li .icon,
.cate-h5-li .title {
  color: #6710F5;
  text-align: center;
  padding-bottom: 0.5rem;
}

.cate-h5-li .title,
.cate-h5-li .descr {
  filter: drop-shadow(0.125rem 0.125rem 0.075rem rgba(0, 0, 0, 0.25));
}
.cate-h5-li .icon {
  grid-area: icon;
  font-size: 2rem;
  display: grid;
  place-items: center;
  border-radius: var(--borderR) 0 0 0;
  position: relative;
}

.cate-h5-li .title {
  grid-area: title;
  font-size: 1.1rem;
  font-weight: bold;
}

.cate-h5-li .descr {
  grid-area: descr;
  font-size: 0.85rem;
  text-align: center;
}
.cate-h5-li .descr::before {
  content: "";
  width: var(--arrowW);
  height: var(--arrowH);
  position: absolute;
  right: 1.5rem;
  top: 100%;
  background: inherit;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.cate-h5-li::after {
  content: counter(cardnr, decimal-leading-zero);
  position: absolute;
  z-index: -1;

  left: calc(var(--inlineP) * -1);
  right: calc(var(--inlineP) * -1);
  top: var(--borderR);
  bottom: calc(var(--borderR) * -1);

  display: flex;
  align-items: flex-end;
  background: #6710F5;
  background-image: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.25),
    transparent 25% 75%,
    rgba(0, 0, 0, 0.25)
  );
  border-radius: 0 0 var(--borderR) 0;

  --pad: 1rem;
  padding: var(--pad);
  font-size: calc(var(--borderR) - var(--pad) * 2);
  color: white;
}
.cate-h5-li::before {
  content: "";
  position: absolute;
  height: var(--borderR);
  top: calc(100% + var(--borderR) - 2px);
  left: calc(var(--inlineP) * -1);
  right: calc(var(--inlineP) * -1);
  border-radius: 0 var(--borderR) 0 0;

  background-image: linear-gradient(#6710F5, transparent 60%);
  opacity: 0.5;
  filter: blur(2px);
}









.post-module:hover,
.hover {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.6;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: ##7C3AED;
  width: 55px;
  height: 55px;
  padding: 8px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 30px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}


.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}
.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}
.container-deals-h5 {
  margin: 0 auto;
}
.container-deals-h5:before,
.container-deals-h5:after {
  content: '';
  display: block;
  clear: both;
}
.container-deals-h5 .column {
  width: 50%;
  padding: 0 25px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.container-deals-h5 .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.container-deals-h5 .info h1 {
  margin: 0 0 5px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.container-deals-h5 .info span {
  color: #666666;
  font-size: 12px;
}
.container-deals-h5 .info span a {
  color: #000000;
  text-decoration: none;
}
.container-deals-h5 .info span .fa {
  color: #e74c3c;
}


.Subscribe-h5-sub::after {
  width: 150%;
  height: 130px;
  content: "";
  z-index: 1;
  position: absolute;
  bottom: -20px;
  left: -25%;
  transform: rotate(-5deg);
  background-color: #F9F9FC;
  transition: transform 1s cubic-bezier(0.2, 0, 0, 1);
}

.Subscribe-h5-sub h3 {
  color: #4F20FF;
  font-size: 22px;
  margin-bottom: 15px;
  transition: opacity 900ms 150ms cubic-bezier(0.2, 0, 0, 1), transform 900ms 150ms cubic-bezier(0.2, 0, 0, 1);
}

.Subscribe-h5-sub p {
  color: #827C8C;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 30px;
  transition: opacity 900ms 100ms cubic-bezier(0.2, 0, 0, 1), transform 900ms 100ms cubic-bezier(0.2, 0, 0, 1);
}


.Subscribe-h5-sub form::after {
  top: 0;
  content: "";
  width: 30px;
  height: 100%;
  right: 110px;
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
}


.Subscribe-h5-sub form button {
  top: 0;
  right: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  height: 100%;
  color: #5224FF;
  font-size: 15px;
  position: absolute;
  background-color: white;
  transition: color 500ms;
}
.Subscribe-h5-sub form button:hover {
  color: #1A0373;
}

.Loading {
  left: 0;
  opacity: 0;
  z-index: 0;
  width: 100%;
  height: 20px;
  bottom: 50px;
  position: absolute;
  text-align: center;
  potiner-events: none;
  transition: opacity 900ms 0s cubic-bezier(0.2, 0, 0, 1), transform 900ms 0s cubic-bezier(0.2, 0, 0, 1);
}
.Loading .LoadingDot {
  width: 6px;
  opacity: 0;
  height: 6px;
  margin: 0 1px;
  border-radius: 50%;
  display: inline-block;
  background-color: #B6B6CF;
}
.Loading .LoadingDot:nth-child(1) {
  animation: loading 2s 0ms infinite;
}
.Loading .LoadingDot:nth-child(2) {
  animation: loading 2s 200ms infinite;
}
.Loading .LoadingDot:nth-child(3) {
  animation: loading 2s 400ms infinite;
}
.Loading .LoadingDot:nth-child(4) {
  animation: loading 2s 600ms infinite;
}
.Loading span {
  color: #B6B6CF;
  display: block;
  font-size: 12px;
  margin-top: 15px;
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  33% {
    opacity: 1;
    transform: scale(1);
  }
  66% {
    opacity: 0;
    transform: scale(0.5);
  }
}
.Complete {
  left: 0;
  top: 50px;
  width: 100%;
  padding: 0 30px;
  text-align: center;
  position: absolute;
}
.Complete .Tick {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 30px;
  display: inline-block;
  background-color: #4F20FF;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 600ms 200ms, transform 600ms 200ms;
}
.Complete .Tick svg {
  opacity: 0;
  margin-top: 28px;
  transform: scale(0.5);
  transition: opacity, 400ms 500ms, transform 300ms 500ms cubic-bezier(0.2, 0, 0, 1.2);
}
.Complete h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(-15px);
  transition: opacity 600ms 450ms, transform 600ms 450ms;
}
.Complete span {
  opacity: 0;
  color: #827C8C;
  display: block;
  font-size: 14px;
  transform: translateY(-15px);
  transition: opacity 600ms 400ms, transform 600ms 400ms;
}

.Subscribe-h5-sub--loading form,
.Subscribe--complete form {
  transform: translateY(100px);
}
.Subscribe-h5-sub--loading::after,
.Subscribe--complete::after {
  transform: rotate(0) translateY(100px);
}
.Subscribe-h5-sub--loading .Loading,
.Subscribe-h5-sub--complete .Loading {
  opacity: 1;
  pointer-events: all;
}

.Subscribe-h5-sub--complete .Loading {
  opacity: 0;
  transform: translateY(100px);
}
.Subscribe-h5-sub--complete h3 {
  transform: translateY(100px);
  opacity: 0;
}
.Subscribe-h5-sub--complete p {
  transform: translateY(100px);
  opacity: 0;
}
.Subscribe-h5-sub--complete .Complete .Tick {
  opacity: 1;
  transform: scale(1);
}
.Subscribe-h5-sub--complete .Complete .Tick svg {
  opacity: 1;
  transform: scale(1);
}
.Subscribe-h5-sub--complete .Complete h4,
.Subscribe-h5-sub--complete .Complete span {
  opacity: 1;
  transform: translateY(0);
}

.Container-h5-sub small {
  width: 100%;
  display: block;
  color: #B6B6CF;
  font-size: 14px;
  padding: 0 30px;
  line-height: 22px;
  text-align: center;
}

#h5-nav-search-but{
  
  display: inline-block;
  outline: 0;
  appearance: none;
  padding: 0px 12px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  background-color: rgb(249, 251, 250);
  border: 1px solid rgb(137, 151, 155);
  box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
  color: rgb(61, 79, 88);
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  transition: all 150ms ease-in-out 0s;
}
#h5-nav-search-button  :hover {
  color: rgb(61, 79, 88);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(93, 108, 116);
  box-shadow: rgb(0 0 0 / 30%) 0px 4px 4px, rgb(231 238 236) 0px 0px 0px 3px;
}