body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  flex-wrap: wrap;

}

.custom-pagination .btn-green:active,
.custom-pagination .btn-green:focus {
  box-shadow: none !important;
  color: #FFFFFF;
}
.btn-green{
  background-color: #007aff;
  color: #FFFFFF;
}

.custom-pagination button {
  border: 1px solid #cdcdcd !important;
  border-radius: none;
}
.custom-pagination button:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.custom-pagination button:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.custom-pagination button:not(:first-child) {
  border-left: none !important;
}

div.custom-pagination-pages {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: "600px") {
  div.custom-pagination-pages {
    padding: 1rem 0 0 0;
    margin-left: 17px;
  }
}

/* make the row and column take up same amount of height */
.custom-row {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.custom-row > [class*="col-"] {
  display: flex !important;
  flex-direction: column !important;
}

.banner-carousel{
  height: 434px;
  max-width: 100%;
  margin-top: 1rem;
}

.banner-carousel .slick-slider{
  height: 440px;
}

.banner-carousel .slick-slide > div{
  height: 419px;
}

.banner-carousel .slick-dots{
  bottom: -10px;
}

.banner-carousel .slick-prev::before,.banner-carousel .slick-next::before{
  color: #FF5722;
}

.banner-carousel--item{
  height:100%;
  background-color: #f7f7f7;
}

.banner-carousel--item>div{
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.grecaptcha-badge {
  visibility: hidden;
}

.show-recaptcha-badge .grecaptcha-badge {
  visibility: visible;
}